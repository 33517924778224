.filtered-data {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 85%;
}

.data-item {
  border: 1px solid #ddd;
  padding: 16px;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.data-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.school-name {
  font-weight: bold;
  margin-bottom: 8px;
}

.contact-name,
.address {
  margin-bottom: 4px;
  color: #666;
}

.no-results {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.no-results p {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.filtered-data, .no-results {
  margin: 40px;
}

@media (max-width: 900px) {
  .filtered-data {
   max-width: 100%;
   margin: 40px 0;
  }

  .no-results p {
    max-width: 300px;
  }
}