.sidebar {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f5f5f5; 
  max-width: 15%;
  width: 100%;
  box-sizing: border-box;
}

.sidebar h2 {
  color: #333; 
  margin-bottom: 20px;
}

.sidebar select {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc; 
  border-radius: 4px;
  background-color: white;
  -webkit-appearance: none; 
  -moz-appearance: none; 
  appearance: none; 
}

/* This is to style the dropdown arrow */
.sidebar select {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px top 50%, #ffffff;
  background-size: 12px 12px;
}

@media (max-width: 900px) {
  .sidebar {
    max-width: 100%;
    justify-content: center;
    align-items: center;
  }

  .sidebar select {
    max-width: 300px;
    width: 100%;
  }
}