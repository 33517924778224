.header-container {
  background: linear-gradient(to right, #00a79d, #00af90, #23b67c, #4bbb63, #6fbe44);
  width: 100%;
}

.header-holder {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.logo {
  width: 50px;
  margin-right: 20px;
}

.navigation {
  display: flex;
  justify-content: space-around;
  max-width: 600px;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
  padding: 5px 30px;
}

.nav-link:hover {
  opacity: 0.8;
}

.menu-toggle {
  display: none;
}

.menu-bar {
  width: 100%;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
}

.menu-bar.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-bar.open:nth-child(2) {
  opacity: 0;
}

.menu-bar.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

@media (max-width: 600px) {
  .navigation {
    display: none;
    flex-direction: column;
    align-items: center;
  }

  .header-holder {
    justify-content: space-between;
  }

  .navigation.open {
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    background: linear-gradient(to right, #00a79d, #00af90, #23b67c, #4bbb63, #6fbe44);
    padding: 20px;
  }

  .nav-link {
    margin-bottom: 10px;
  }

  .menu-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 24px;
    height: 20px;
    cursor: pointer;
  }
}