.page-container {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0 auto;
  max-width: 100%;
}

@media (max-width: 900px) {
  .page-container {
    flex-direction: column;
    align-items: center;
  }
}
