/* Card.css */
.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 500px;
  width: 100%;
  margin: 20px;
}

.card-divider {
  height: 2px;
  background-color: #999999;
  max-width: 90%;
  margin: 0 auto;
}

.card-image {
  width: 100%;
  margin-bottom: -4px;
}

.card-header {
  text-align: center;
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: 500;
}

.card-body {
  padding: 1rem;
  text-align: center;
}

.card-contact,
.card-address,
.card-phone,
.card-email {
  margin-bottom: 0.5rem;
  color: #333;
}

.card-email {
  margin-bottom: 30px;
}

.card-email a,
.card-website {
  text-decoration: none;
  color: inherit;
}

.card-website button {
  background: linear-gradient(to right, #f89f3b, #fd654e);
  padding: 10px 20px;
  color: white;
  border: none;
  cursor: pointer;
}
