.reset-filter-button {
    padding: 10px;
    border: 1px solid #f44336;
    border-radius: 4px;
    color: #f44336;
    cursor: pointer;
  }
  
  .reset-filter-button:hover {
    background-color: #d32f2f; /* Adjust color to match your design */
    color: #fff;
  }